import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import FeaturePost from '../../../components/feature-post/feature-post';
import { BannerWrapper, BannerInner, UpcomingSeminars, Title } from './style';
import useDateFormatter from '../../../hooks/useDateFormatter';

type BannerProps = {};

const Banner: React.FunctionComponent<BannerProps> = () => {
  const { dateFormatter } = useDateFormatter();

  const Data: {
    allMarkdownRemark: { edges: { node: { frontmatter: { date: string } } }[] };
  } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 3
      ) {
        totalCount
        edges {
          node {
            excerpt(pruneLength: 300)
            fields {
              slug
            }
            frontmatter {
              date
              title
              description
              tags
              cover {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  `);

  const Posts = Data.allMarkdownRemark.edges.sort(
    (a, b) =>
      new Date(a.node.frontmatter.date).getTime() -
      new Date(b.node.frontmatter.date).getTime()
  );

  const filteredPosts = Posts.filter(
    ({ node }: any) =>
      new Date().getTime() <= new Date(`${node.frontmatter.date}`).getTime()
  );

  return (
    <BannerWrapper>
      <BannerInner>
        <UpcomingSeminars>
          <Title>
            {filteredPosts.length > 0
              ? 'Upcoming Seminars'
              : 'No upcoming seminars.'}
          </Title>
          {filteredPosts.map(({ node }: any) => {
            const title = node.frontmatter.title || node.fields.slug;

            // Random Placeholder Color
            const placeholderColors = [
              '#55efc4',
              '#81ecec',
              '#74b9ff',
              '#a29bfe',
              '#ffeaa7',
              '#fab1a0',
              '#e17055',
              '#0984e3',
              '#badc58',
              '#c7ecee',
            ];

            const setColor =
              placeholderColors[
                Math.floor(Math.random() * placeholderColors.length)
              ];

            return (
              <FeaturePost
                key={node.fields.slug}
                title={title}
                image={
                  node.frontmatter.cover == null
                    ? null
                    : node.frontmatter.cover.childImageSharp.gatsbyImageData
                }
                url={node.fields.slug}
                tags={node.frontmatter.tags}
                placeholderBG={setColor}
                date={dateFormatter(
                  'America/Los_Angeles',
                  new Date(node.frontmatter.date)
                )}
              />
            );
          })}
        </UpcomingSeminars>
      </BannerInner>
    </BannerWrapper>
  );
};

export default Banner;
