import * as React from 'react';
import DivWrapper from './style';
import Banner from './banner';

type HomeProps = {};

const UpcomingSeminars: React.FunctionComponent<HomeProps> = (props) => {
  return (
    <DivWrapper {...props}>
      <Banner />
    </DivWrapper>
  );
};

export default UpcomingSeminars;
